<template>
  <div>
    <comments-component />
  </div>
</template>

<script>
import CommentsComponent from "@/components/comments/CommentsComponent";

export default {
  name: "Comments",
  title: "Notas y Comentarios | Baja California Health Tourism",
  components: {
    CommentsComponent,
  },
};
</script>

<style scoped></style>
